import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";

import JoditEditor from "jodit-react";
import Loading from "../../Loading/Loading";

const config = {
  buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
};

const EditWorkingProcess = () => {
  // const [getFooterData, setGetFooter] = useState({});
  const [title, setTitle] = useState("");
  const [heading_one, setHeading_one] = useState("");
  const [heading_one_description, setHeading_one_description] = useState("");
  const [heading_two, setHeading_two] = useState("");
  const [heading_two_description, setHeading_two_description] = useState("");
  const [heading_three, setHeading_three] = useState("");
  const [heading_three_description, setHeading_three_description] =
    useState("");
  const editor = useRef(null);
  const [dataLoadng, setDataLoading] = useState(false);

  const getFooter = async () => {
    setDataLoading(true);

    const url = `/api/workingprocess/`;
    const { data } = await axiosBaseUrl.get(url);
    if (data.status) {
      setDataLoading(false);

      if (data.data.results.length > 0) {
        setTitle(data.data.results[0].title);
        setHeading_one(data.data.results[0].heading_one);
        setHeading_one_description(
          data.data.results[0].heading_one_description
        );

        setHeading_two(data.data.results[0].heading_two);
        setHeading_two_description(
          data.data.results[0].heading_two_description
        );

        setHeading_three(data.data.results[0].heading_three);
        setHeading_three_description(
          data.data.results[0].heading_three_description
        );
      }
    }
  };

  useEffect(() => {
    getFooter();
  }, []);
  // console.log(getFooterData);

  const UpdateHandler = async (e) => {
    e.preventDefault();
    setDataLoading(true);
    const footerData = {
      title,
      heading_one,
      heading_one_description,
      heading_two,
      heading_two_description,
      heading_three,
      heading_three_description,
    };
    try {
      const { data } = await axiosBaseUrl.post(
        `/api/workingprocess/`,
        footerData
      );
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        toast(error.response.data.message);
        console.log(error);
        // localStorage.removeItem("token");
        // navigate("/login");
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }

  return (
    <section className="section">
      <Helmet>
        <title>Update Working Process</title>
      </Helmet>
      <div className="section-header">
        <h1>Update workingprocess</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Update Working Pprocess and input not be empty</h4>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Section Title
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      />
                    </div>
                  </div>
                  {/* heading one */}
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Heading One
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="heading_one"
                        onChange={(e) => setHeading_one(e.target.value)}
                        value={heading_one}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Heading One Description
                    </label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={heading_one_description}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) =>
                          setHeading_one_description(newContent)
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  {/* heading two */}
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Heading Two
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="heading_two"
                        onChange={(e) => setHeading_two(e.target.value)}
                        value={heading_two}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Heading Two Description
                    </label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={heading_two_description}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) =>
                          setHeading_two_description(newContent)
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  {/* heading three */}
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Heading three
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="heading_three"
                        onChange={(e) => setHeading_three(e.target.value)}
                        value={heading_three}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Heading three Description
                    </label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={heading_three_description}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) =>
                          setHeading_three_description(newContent)
                        }
                      />
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={UpdateHandler}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditWorkingProcess;
