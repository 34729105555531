import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";

import JoditEditor from "jodit-react";
import Loading from "../../Loading/Loading";

const config = {
  buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
};

const EditFooter = () => {
  const [address_filed, setAddress_filed] = useState("");
  const [city_office, setCity_office] = useState("");
  const [dhaka_office, setDhaka_office] = useState("");
  const [headquarter, setHeadquarter] = useState("");
  const [usa_office, setUsa_office] = useState("");
  const editor = useRef(null);
  const [dataLoadng, setDataLoading] = useState(false);

  useEffect(() => {
    const getFooter = async () => {
      setDataLoading(true);

      const url = `/api/footer/`;
      const { data } = await axiosBaseUrl.get(url);
      if (data.status) {
        setDataLoading(false);
        if (data.data.results.length > 0) {
          setAddress_filed(data.data.results[0].address_filed);
          setCity_office(data.data.results[0].city_office);
          setDhaka_office(data.data.results[0].dhaka_office);
          setHeadquarter(data.data.results[0].headquarter);
          setUsa_office(data.data.results[0].usa_office);
        }
      }
    };
    getFooter();
  }, []);
  // console.log(getFooterData);

  const UpdateHandler = async (e) => {
    e.preventDefault();
    setDataLoading(true);
    const footerData = {
      address_filed,
      city_office,
      headquarter,
      dhaka_office,
      usa_office,
    };
    try {
      const { data } = await axiosBaseUrl.post(`/api/footer/`, footerData);
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        toast(error.response.data.message);
        console.log(error);
        // localStorage.removeItem("token");
        // navigate("/login");
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }

  return (
    <section className="section">
      <Helmet>
        <title>Update Footer</title>
      </Helmet>
      <div className="section-header">
        <h1>Update Footer</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Update footer and input not be empty</h4>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Address 1</label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={address_filed}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) => setAddress_filed(newContent)}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      City office
                    </label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={city_office}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) => setCity_office(newContent)}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Headquarter
                    </label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={headquarter}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) => setHeadquarter(newContent)}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Dhaka office
                    </label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={dhaka_office}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) => setDhaka_office(newContent)}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      USA office
                    </label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={usa_office}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) => setUsa_office(newContent)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={UpdateHandler}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditFooter;
