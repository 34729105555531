import React, { useEffect, useState } from "react";
import axiosBaseUrl from "../../axiosBaseUrl";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  const [banner, setBanner] = useState("");
  const [post, setPost] = useState("");
  const [categories, setCategories] = useState("");
  const [galleries, setGalleries] = useState("");
  const [videos, setVideos] = useState("");

  useEffect(() => {
    const getdashboard = async () => {
      const url = `/api/dashboard/`;
      try {
        const { data } = await axiosBaseUrl.get(url);
        if (data.status) {
          setBanner(data.banner_count);
          setPost(data.posts_count);
          setCategories(data.categories_count);
          setGalleries(data.galleries_count);
          setVideos(data.videos_count);
        }
      } catch (error) {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          console.log(error);
        }
      }
    };

    getdashboard();
  }, []);
  return (
    <section className="section">
      <div className="section-header">
        <h1>Dashboard</h1>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">
              <h4>Total Banner</h4>{" "}
              <span className="badge bg-warning fs-1 text-dark fw-bold">
                {banner}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">
              <h4>Total Post</h4>{" "}
              <span className="badge bg-warning fs-1 text-dark fw-bold">
                {post}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">
              <h4>Total Category</h4>{" "}
              <span className="badge bg-warning fs-1 text-dark fw-bold">
                {categories}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">
              <h4>Total Gallery</h4>{" "}
              <span className="badge bg-warning fs-1 text-dark fw-bold">
                {galleries}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">
              <h4>Total Videos</h4>{" "}
              <span className="badge bg-warning fs-1 text-dark fw-bold">
                {videos}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
