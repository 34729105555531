
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import axiosBaseUrl from "../../axiosBaseUrl";
import useAdmin from "../Hooks/useAdmin";

const Login = () => {
  const [user, setUser] = useState("");
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const email = localStorage.getItem("email");
  const { token } = useAdmin(user || email);
  useEffect(() => {
    if (token || email) {
      console.log("localUser");
      navigate(from, { replace: true });
      navigate("/admin");
    }
  }, [navigate, from, token, email]);

  const handleLogin = async (data) => {
    const loginData = {
      email: data.email,
      password: data.password,
    };
    // const config = {
    //   headers: {
    //     "content-type": "application/json",
    //   },
    // };

    try {
      const result = await axiosBaseUrl.post(`/api/signin`, loginData);
      if (result.data.status) {
        setUser(result.data.email);
        localStorage.setItem("token", JSON.stringify(result.data.token));
        localStorage.setItem("email", JSON.stringify(result.data.email));
        navigate("/admin");
        reset();
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        // toast(error.response.data.msg);
        console.log(error);
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };

  return (
    <section className="section">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="login-brand login-brand-color">
              <img alt="iage" src="assets/img/logo.png" />
            </div>
            <div className="card">
              <div className="card-header card-header-auth">
                <h4>Login</h4>
              </div>
              <div className="card-body">
                <form
                  onSubmit={handleSubmit(handleLogin)}
                  method="POST"
                  action="#"
                  className="needs-validation"
                  noValidate=""
                >
                  <div className="form-group">
                    <label for="email">Email</label>
                    <input
                      {...register("email", { required: true })}
                      id="email"
                      type="email"
                      className="form-control"
                      name="email"
                      tabIndex="1"
                      required=""
                      autoFocus=""
                    />
                    <div className="invalid-feedback">
                      Please fill in your email
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-block">
                      <label for="password" className="control-label">
                        Password
                      </label>
                      <div className="float-right">
                        <a
                          href="auth-forgot-password.html"
                          className="text-small"
                        >
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    <input
                      {...register("password", { required: true })}
                      id="password"
                      type="password"
                      className="form-control"
                      name="password"
                      tabIndex="2"
                      required=""
                    />
                    <div className="invalid-feedback">
                      please fill in your password
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="remember"
                        className="custom-control-input"
                        tabIndex="3"
                        id="remember-me"
                      />
                      <label className="custom-control-label" for="remember-me">
                        Remember Me
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-lg btn-block btn-auth-color"
                      tabIndex="4"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="mt-5 text-muted text-center">
              Don't have an account? <a href="auth-register.html">Create One</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
