import { createContext, useContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalState = ({ children }) => {
  const [toggleClass, setToggleClass] = useState(false);
  const [toggleMobile, setToggleMobile] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        toggleClass,
        setToggleClass,
        toggleMobile,
        setToggleMobile,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalContexts = () => useContext(GlobalContext);

export default useGlobalContexts;
