import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";
import Loading from "../../Loading/Loading";
import useGlobalContexts from "../../../Context/GlobalContext";

const EditCategory = () => {
  const { setToggleClass } = useGlobalContexts();

  const [filedImageError, setFiledImagError] = useState(null);
  const [categoryItem, setcategoryItem] = useState({});
  const [dataLoadng, setDataLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState({});
  const cat_types = ["news", "service", "concern"];
  const { id } = useParams();

  useEffect(() => {
    setToggleClass(false);
    const getCategory = async () => {
      const url = `/api/category/getcategory`;
      try {
        const { data } = await axiosBaseUrl.get(url);
        if (data.status) {
          const results = data.data.results.filter((c) => c.id != id);
          setCategories(results);
        }
      } catch (error) {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          console.log(error);
        }
      }
    };
    getCategory();
  }, [setToggleClass, id]);
  useEffect(() => {
    const getCategoryById = async () => {
      const url = `/api/category/${id}`;
      const { data } = await axiosBaseUrl.get(url);
      if (data.status) {
        setcategoryItem(data.data);
        setData({
          name: data.data.name,
          image: data.data.image,
          image_url: data.data.image,
          parentId: data.data.parentId,
          cat_type: data.data.cat_type,
        });
        setDataLoading(false);
      }
    };
    getCategoryById();
  }, [id]);

  const onchaneCheckExtension = (fileData) => {
    var fileTypes = ["jpg", "jpeg", "png"];
    var extension = fileData.name.split(".").pop().toLowerCase();
    const isSuccess = fileTypes.indexOf(extension) > -1;
    if (!isSuccess) {
      setFiledImagError("Select image png,jpg,jpeg");
      return false;
    }
    setFiledImagError(null);
  };

  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  function handleFile(e) {
    onchaneCheckExtension(e.target.files[0]);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      const newData = { ...data };
      newData["image"] = e.target.files[0];
      newData["image_url"] = reader.result;
      setData(newData);
    };
  }

  const updateHandler = async (e) => {
    e.preventDefault();
    if (filedImageError) {
      return false;
    }
    setDataLoading(true);
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("name", data.name);
    formData.append("parentId", data.parentId);
    formData.append("cat_type", data.cat_type);
    try {
      const { data } = await axiosBaseUrl.put(`/api/category/${id}/`, formData);
      if (data.status) {
        toast.success(data.message);
        setFiledImagError(null);
        setDataLoading(false);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        toast(error.response.data.message);
        console.log(error);
        // localStorage.removeItem("token");
        // navigate("/login");
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }
  return (
    <section className="section">
      <Helmet>
        <title>Update category</title>
      </Helmet>
      <div className="section-header">
        <h1>Update Category</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Add Category and input not be empty</h4>
                  <Link to="/admin/category" className="btn btn-primary">
                    All Category
                  </Link>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={(e) => handle(e)}
                        value={data.name}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Select Parent Category
                    </label>

                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="parentId"
                        onChange={(e) => handle(e)}
                      >
                        <option value="">Select Parent Category</option>
                        {categories &&
                          categories.map((item, i) => {
                            return (
                              <option
                                selected={item.id == categoryItem.parentId}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Category type
                    </label>

                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="cat_type"
                        onChange={(e) => handle(e)}
                      >
                        <option value="">Select Category type</option>
                        {cat_types &&
                          cat_types.map((item, i) => {
                            return (
                              <option
                                key={item}
                                selected={item === categoryItem.cat_type}
                                value={item}
                              >
                                {item}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Image</label>
                    <div className="col-sm-9">
                      <input
                        onChange={(e) => handleFile(e)}
                        type="file"
                        // {...register("image")}
                        className="form-control"
                      />
                      <img
                        src={data.image_url}
                        id="imageNone"
                        width="100px"
                        alt=""
                      />
                      {filedImageError && <p>{filedImageError}</p>}
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="submit"
                    disabled={filedImageError}
                    className="btn btn-primary"
                    onClick={updateHandler}
                  >
                    Update Category
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditCategory;
