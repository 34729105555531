import React from "react";
import { useNavigate } from "react-router-dom";
import useGlobalContexts from "../../Context/GlobalContext";
import "./HeaderNav.css";
import profile from "./../assets/profile.png";
const HeaderNav = () => {
  const { toggleClass, setToggleClass, toggleMobile, setToggleMobile } =
    useGlobalContexts();
  const handleToggle = () => {
    setToggleClass(!toggleClass);
  };
  const handleToggleMobile = () => {
    setToggleMobile(!toggleMobile);
  };
  let navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/login");
  };

  // let bdy = body;

  return (
    <>
      <div className="navbar-bg"></div>
      <nav className="navbar navbar-expand-lg main-navbar">
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              {!toggleMobile && (
                <span
                  onClick={handleToggle}
                  data-toggle="sidebar"
                  className="nav-link nav-link-lg collapse-btn"
                >
                  <i className="fas fa-bars"></i>
                </span>
              )}
              {toggleMobile && (
                <div onClick={handleToggleMobile}>
                  <i className="fa-solid fa-arrow-right"></i>
                </div>
              )}
            </li>
            {/* <li>
              <a
                href="#"
                data-toggle="sidebar"
                className="nav-link nav-link-lg collapse-btn"
              >
                <i className="fas fa-bars"></i>
              </a>
            </li> */}
          </ul>
        </div>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <a
              href="/"
              id="profile"
              data-bs-toggle="dropdown"
              className="nav-link dropdown-toggle1 nav-link-lg nav-link-user"
            >
              <img
                alt="images"
                src={profile}
                className="user-img-radious-style"
              />
              <span className="d-sm-none d-lg-inline-block"></span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="profile"
            >
              <div className="dropdown-divider"></div>
              <span
                className="dropdown-item has-icon text-danger logo-auth"
                onClick={handleLogout}
              >
                <i className="fas fa-sign-out-alt "></i> <span>Logout</span>
              </span>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default HeaderNav;
