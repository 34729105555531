
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../axiosBaseUrl";

const useAdmin = (user) => {
  const [admin, setAdmin] = useState(false);
  const [adminLoading, setAdminLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const getAdmin = async () => {
      const email = user;
      if (email) {
        try {
          const { data } = await axiosBaseUrl.get(
            `/api/authVerifyUser/${email}`
          );
          // ;
          if (data.status) {
            setAdmin(data.status);
            setAdminLoading(false);
          }
        } catch (error) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            setAdmin(false);
            setAdminLoading(false);
            toast(error.response.data.msg);
            // signOut(auth);
            localStorage.removeItem("token");
            navigate("/login");
          }
        }
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        setAdmin(false);
        setAdminLoading(false);
        navigate("/login");
      }
    };
    getAdmin();
  }, [user, navigate]);

  return { admin, adminLoading };
};

export default useAdmin;
