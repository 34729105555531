import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";
import Loading from "../../Loading/Loading";
import PriviewImage from "./../Category/PriviewImage";
import useGlobalContexts from "../../../Context/GlobalContext";
import { useEffect } from "react";

const AddBanner = () => {
  const { setToggleClass } = useGlobalContexts();
  const [filedImage, setFiledImage] = useState(null);
  const [filedImageError, setFiledImagError] = useState(null);
  const [dataLoadng, setDataLoading] = useState(false);

  useEffect(() => {
    setToggleClass(false);
  }, [setToggleClass]);
  const onchaneCheckExtension = (fileData) => {
    var fileTypes = ["jpg", "jpeg", "png"];
    var extension = fileData.name.split(".").pop().toLowerCase();
    const isSuccess = fileTypes.indexOf(extension) > -1;
    if (!isSuccess) {
      setFiledImagError("Select image png,jpg,jpeg");
      return false;
    }
    setFiledImagError(null);
  };

  const [data, setData] = useState({
    title: "",
    image: "",
    image_url: "",
    description: "",
    btn_text: "",
    btn_link: "",
  });

  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  function handleFile(e) {
    onchaneCheckExtension(e.target.files[0]);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      const newData = { ...data };
      newData["image"] = e.target.files[0];
      newData["image_url"] = reader.result;
      setData(newData);
    };
  }

  const addBannerHandler = async (e) => {
    e.preventDefault();
    if (filedImageError) {
      return false;
    }
    setDataLoading(true);
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("btn_text", data.btn_text);
    formData.append("btn_link", data.btn_link);

    try {
      const { data } = await axiosBaseUrl.post(`/api/banner/create/`, formData);
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
        setFiledImage(null);
        setFiledImagError(null);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        toast(error.response.data.message);
        // console.log(error);
        // localStorage.removeItem("token");
        // navigate("/login");
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }
  return (
    <section className="section">
      <Helmet>
        <title>Add banner</title>
      </Helmet>
      <div className="section-header">
        <h1>Add banner</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Add banner and input not be empty</h4>
                  <Link to="/admin/banner" className="btn btn-primary">
                    All Banner
                  </Link>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Title</label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            onChange={(e) => handle(e)}
                            value={data.title}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Description
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            col="100%"
                            type="text"
                            className="form-control"
                            name="description"
                            onChange={(e) => handle(e)}
                            value={data.description}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Button text
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="btn_text"
                            onChange={(e) => handle(e)}
                            value={data.btn_text}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Button link
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="btn_link"
                            onChange={(e) => handle(e)}
                            value={data.btn_link}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Image</label>
                        <div className="col-sm-9">
                          <input
                            data-height="100"
                            onChange={(e) => handleFile(e)}
                            type="file"
                            className="form-control"
                          />
                          <img src={data.image_url} width="100px" alt="" />
                          {filedImageError && <p>{filedImageError}</p>}
                          {filedImage && <PriviewImage file={filedImage} />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={addBannerHandler}
                    // disabled={filedImageError}
                    className="btn btn-primary"
                  >
                    Add Banner
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddBanner;
