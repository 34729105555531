import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loading from "../../Loading/Loading";
import useGlobalContexts from "../../../Context/GlobalContext";

const AddPost = () => {
  const { setToggleClass } = useGlobalContexts();

  const [filedImageError, setFiledImagError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [dataLoadng, setDataLoading] = useState(false);

  const [data, setData] = useState({
    title: "",
    image: "",
    image_url: "",excerpt: "",
    description: "",
    categoryId: "",
    post_type: "",
  });
  const [introduction, setIntroduction] = useState("");
  const [errordata, setErrorData] = useState({
    title: "",
    description: "",excerpt: "",
    category: "",
  });

  const onchaneCheckExtension = (fileData) => {
    var fileTypes = ["jpg", "jpeg", "png"];
    var extension = fileData.name.split(".").pop().toLowerCase();
    const isSuccess = fileTypes.indexOf(extension) > -1;
    if (!isSuccess) {
      setFiledImagError("Select image png,jpg,jpeg");
      return false;
    }
    setFiledImagError(null);
  };

  // get categories
  const getCategory = async () => {
    const url = `/api/category/getcategory`;
    try {
      const { data } = await axiosBaseUrl.get(url);
      if (data.status) {
        setCategories(data.data.results);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setToggleClass(false);
    getCategory();
  }, [setToggleClass]);

  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  function handleFile(e) {
    onchaneCheckExtension(e.target.files[0]);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      const newData = { ...data };
      newData["image"] = e.target.files[0];
      newData["image_url"] = reader.result;
      setData(newData);
      // console.log(reader.result); //base64encoded string
    };
  }
  function handleIntroduction(intro) {
    const newData = { ...data };
    newData["description"] = intro;
    setData(newData);
    setIntroduction(intro);
  }
  const addBannerHandler = async (e) => {
    e.preventDefault();
    if (filedImageError) {
      return false;
    }
    setDataLoading(true);
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("title", data.title);
    formData.append("excerpt", data.excerpt);
    formData.append("description", data.description);
    formData.append("categoryId", data.categoryId);
    formData.append("post_type", data.post_type);

    try {
      const { data } = await axiosBaseUrl.post(`/api/post/create/`, formData);
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
        setFiledImagError(null);
        setData({
          title: "",
          excerpt: "",
          description: "",
          categoryId: "",
          image: "",
          image_url: "",
          post_type: "",
        });
        setErrorData({
          title: "",
          excerpt: "",
          description: "",
          category: "",
          post_type: "",
        });
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);

        // toast(error.response.data.msg);
        console.log(error.response.data);
        const erorMessage = error.response.data;
        if (erorMessage.status === false) {
          setErrorData({
            title: erorMessage?.title,
            description: erorMessage?.description,
            category: erorMessage?.category,
            post_type: erorMessage?.post_type,
          });
        }
        // localStorage.removeItem("token");
        // navigate("/login");
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }
  return (
    <section className="section">
      <Helmet>
        <title>Add Post</title>
      </Helmet>
      <div className="section-header">
        <h1>Add Post</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Add banner and input not be empty</h4>
                  <Link to="/admin/post" className="btn btn-primary">
                    All Post
                  </Link>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Title</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={(e) => handle(e)}
                        value={data.title}
                      />
                      <span className="text-danger">{errordata.title}</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Post type</label>

                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="post_type"
                        required
                        onChange={(e) => handle(e)}
                      >
                        <option value="" defaultValue>
                          Select Post type
                        </option>
                        <option value="news">News</option>
                        <option value="service">Service</option>
                        <option value="concern">Concern</option>
                      </select>
                      <span className="text-danger">{errordata.post_type}</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                    Excerpt
                    </label>
                    <div className="col-sm-9">
                    <textarea rows = "1" value={data.excerpt} cols = "10" name = "excerpt" onChange={(e) => handle(e)}  className="form-control" placeholder="Excerpt">
         </textarea>
                      <span className="text-danger">
                        {errordata.excerpt}
                      </span>
                    </div>
                  </div>
                  
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Description
                    </label>
                    <div className="col-sm-9">
                      <CKEditor
                        editor={ClassicEditor}
                        data={introduction}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          handleIntroduction(editor.getData());
                        }}
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        name="description"
                        onChange={(e) => handle(e)}
                        value={data.description}
                      /> */}
                      <span className="text-danger">
                        {errordata.description}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Select Category
                    </label>

                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="categoryId"
                        onChange={(e) => handle(e)}
                        required
                      >
                        <option>Select Category</option>
                        {categories &&
                          categories.map((item, i) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                      <span className="text-danger">{errordata.category}</span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Image</label>
                    <div className="col-sm-9">
                      <input
                        data-height="100"
                        onChange={(e) => handleFile(e)}
                        type="file"
                        className="form-control"
                      />
                      <img src={data.image_url} width="100px" alt="" />
                      {filedImageError && <p>{filedImageError}</p>}
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={addBannerHandler}
                    // disabled={filedImageError}
                    className="btn btn-primary"
                  >
                    Add Post
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddPost;
