import React, { useState } from "react";

const PriviewImage = ({ file }) => {
  const [preview, setPreview] = useState(null);

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result);
  };
  return (
    <div>
      <img src={preview} alt="" width="100px" />
    </div>
  );
};

export default PriviewImage;
