import React from "react";
import { Link, NavLink } from "react-router-dom";

const SidebarItem = ({ menu }) => {
  return (
    <>
      {menu.subMenus?.length > 0 ? (
        <li className="dropdown custom_dropdown">
          <Link
            to={menu.to}
            className={
              menu && menu?.subMenus?.length > 0
                ? " dropdown-toggle "
                : "nav-link dropdown-item"
            }
            // id={menu.to}
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <i className={menu.icon}></i>
            <span>{menu.display_name}</span>
          </Link>
          {menu.subMenus && (
            <ul
              className="dropdown-menu custom_dropdown_menu "
              aria-labelledby={menu.to}
            >
              {menu.subMenus.map((submenu, index) => (
                <li key={index}>
                  <NavLink className="nav-link dropdown-item" to={submenu.to}>
                    <span>{submenu.display_name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </li>
      ) : (
        <>
          <li className="dropdown">
            <NavLink to={menu.to} className="nav-lin dropdown-item">
              <i className={menu.icon}></i>
              <span>{menu.display_name}</span>
            </NavLink>
          </li>
        </>
      )}
    </>
  );
};

export default SidebarItem;
