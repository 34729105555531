import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";
import useGlobalContexts from "../../../Context/GlobalContext";
import { useEffect } from "react";

const AddVideo = () => {
  const { setToggleClass } = useGlobalContexts();

  const [dataLoadng, setDataLoading] = useState(false);
  const [uploaded, setUploaded] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideos] = useState([]);
  useEffect(() => {
    setToggleClass(false);
  }, [setToggleClass]);
  const addHandler = async (e) => {
    e.preventDefault();

    setDataLoading(true);

    let formdata = new FormData();

    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("video", video);

    try {
      const { data } = await axiosBaseUrl.post(
        `/api/media/create/admin/`,
        formdata,
        {
          onUploadProgress: (data) => {
            setUploaded(Math.round((data.loaded / data.total) * 100));
          },
        }
      );
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
        setTitle("");
        setDescription("");
      }
    } catch (error) {
      setDataLoading(true);
      console.log(error);
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);

        toast(error.response.data.message);
      }
    }
  };
  // if (dataLoadng) {
  //   return <Loading />;
  // }
  return (
    <section className="section">
      <Helmet>
        <title>Add Video</title>
      </Helmet>
      <div className="section-header">
        <h1>Add Video</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Add Video and input not be empty</h4>
                  <Link to="/admin/video" className="btn btn-primary">
                    All Video
                  </Link>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Title</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="Title"
                        id="title"
                        className="form-control"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      description
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        row="5"
                        col="100%"
                        type="text"
                        name="description"
                        value={description}
                        id="description"
                        className="form-control"
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Video upload
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="file"
                        name="video"
                        id="video"
                        className="form-control"
                        accept=".mp4, .mkv"
                        onChange={(e) => {
                          setVideos(e.target.files[0]);
                        }}
                      />
                      {/* <img src={data.image_url} width="100px" alt="" /> */}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6 col-form-label">
                      {uploaded && (
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            aria-valuenow={uploaded}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: `${uploaded}%` }}
                          >
                            {`${uploaded}%`}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={addHandler}
                    disabled={uploaded}
                    className="btn btn-primary"
                  >
                    Add Video
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddVideo;
