import axios from "axios";
import { env } from "./env";

var headers = {};

const axiosBaseUrl = axios.create({
  baseURL: env.baseUrl,
  headers,
});

// Add a request interceptor
// axiosBaseUrl.interceptors.request.use(
//   async function (config) {
//     // Do something before request is sent

//     Object.assign(config.headers, { testHeader: "This is the test header" });

//     try {
//       const user = JSON.parse(localStorage.getItem("token"));
//       console.log(user);
//       if (user !== null) {
//         // console.log("HERE IN AXIOS BASE URL: ", user)

//         Object.assign(config.headers, { Authorization: "Bearer " + user });
//       }
//     } catch (err) {
//       // error reading value
//       console.log("Err while fetching: ", err);
//     }

//     // console.log(config.headers)

//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );
axiosBaseUrl.interceptors.request.use(
  async function (config) {
    // Do something before request is sent

    Object.assign(config.headers, { testHeader: "This is the test header" });

    try {
      const user = await JSON.parse(localStorage.getItem("token"));

      if (user !== null) {
        // console.log("HERE IN AXIOS BASE URL: ", user)

        Object.assign(config.headers, { Authorization: "Bearer " + user });
      }
    } catch (err) {
      // error reading value
      console.log("Err while fetching: ", err);
    }

    // console.log(config.headers)

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// axiosBaseUrl.interceptors.request.use(
//   (config) => {
//     if (!config.headers.Authorization) {
//       const token = JSON.parse(localStorage.getItem("token"));

//       if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//       }
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );
// Add a response interceptor
axiosBaseUrl.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosBaseUrl;
