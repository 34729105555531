// import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Dashboard from "./components/Content/Dashboard";
import ManageCategory from "./components/Content/Category/ManageCategory";
import AddCategory from "./components/Content/Category/AddCategory";
import Login from "./components/Login/Login";
import RequireAdmin from "./components/RequireAuth/RequireAdmin";
import EditCategory from "./components/Content/Category/EditCategory";
import ManageBanner from "./components/Content/Banner/ManageBanner";
import AddBanner from "./components/Content/Banner/AddBanner";
import EditBanner from "./components/Content/Banner/EditBanner";
import ManagePost from "./components/Content/Post/ManagePost";
import AddPost from "./components/Content/Post/AddPost";
import EditPost from "./components/Content/Post/EditPost";
import AddGallery from "./components/Content/Gallery/AddGallery";
import ManageGallery from "./components/Content/Gallery/ManageGallery";
import EditGallery from "./components/Content/Gallery/EditGallery";
import EditFooter from "./components/Content/Footer/EditFooter";
import EditSocial from "./components/Content/Social/EditSocial";
import EditCopyRight from "./components/Content/CopyRight/EditCopyRight";
import EditAbout from "./components/Content/About/EditAbout";
import EditCeoSay from "./components/Content/About/EditCeoSay";
import EditWorkingProcess from "./components/Content/CopyRight/EditWorkingProcess";
import ManageCounDown from "./components/Content/CounDown/ManageCounDown";
import AddCounDown from "./components/Content/CounDown/AddCounDown";
import EditCounDown from "./components/Content/CounDown/EditCounDown";
import AddVideo from "./components/Content/video/AddVideo";
import ManageVideo from "./components/Content/video/ManageVideo";
import useGlobalContexts from "./Context/GlobalContext";
import ServicesSection from "./components/Content/Section/ServicesSection";
import EditSection from "./components/Content/Section/EditSection";
import ManageSection from "./components/Content/Section/ManageSection";
import EditLogo from "./components/Content/About/EditLogo";
import ManageContact from "./components/Content/Contact/ManageContact";
import AddContact from "./components/Content/Contact/AddContact";
import EditContact from "./components/Content/Contact/EditContact";
import EditVideo from "./components/Content/video/EditVideo";
import ManageGalleryPost from "./components/Content/GalleryPost/ManageGalleryPost";
import AddGalleryPost from "./components/Content/GalleryPost/AddGalleryPost";
import EditGalleryPost from "./components/Content/GalleryPost/EditGalleryPost";

function App() {
  const { toggleClass, toggleMobile } = useGlobalContexts();

  return (
    <>
      <div
        className={`app light purple-sidebar theme-white  ${
          toggleClass ? " sidebar-mini" : ""
        }
        ${toggleMobile ? " toggle_sidebar" : ""}`}
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin"
            element={
              <RequireAdmin>
                <Layout />
              </RequireAdmin>
            }
          >
            <Route path="/admin" element={<Dashboard />} />
            {/* category */}
            <Route path="category" element={<ManageCategory />} />
            <Route path="category/add" element={<AddCategory />} />
            <Route path="category/edit/:id" element={<EditCategory />} />
            {/* Banner */}
            <Route path="banner" element={<ManageBanner />} />
            <Route path="banner/add" element={<AddBanner />} />
            <Route path="banner/edit/:id" element={<EditBanner />} />
            {/* Post */}
            <Route path="post" element={<ManagePost />} />
            <Route path="post/add" element={<AddPost />} />
            <Route path="post/edit/:id" element={<EditPost />} />
            {/* gallery */}
            <Route path="gallery" element={<ManageGallery />} />
            <Route path="gallery/add" element={<AddGallery />} />
            <Route path="gallery/edit/:id" element={<EditGallery />} />
            {/* gallery post */}
            <Route path="gallerypost" element={<ManageGalleryPost />} />
            <Route path="gallerypost/add" element={<AddGalleryPost />} />
            <Route path="gallerypost/edit/:id" element={<EditGalleryPost />} />

            {/* video */}
            <Route path="video" element={<ManageVideo />} />
            <Route path="video/add" element={<AddVideo />} />
            <Route path="video/edit/:id" element={<EditVideo />} />

            {/* count down */}
            <Route path="countdown" element={<ManageCounDown />} />
            <Route path="countdown/add" element={<AddCounDown />} />
            <Route path="countdown/edit/:id" element={<EditCounDown />} />
            {/* count down */}

            <Route path="about" element={<EditAbout />} />
            <Route path="logo" element={<EditLogo />} />

            <Route path="section" element={<ManageSection />} />
            <Route path="section/add" element={<ServicesSection />} />
            <Route path="section/edit/:id" element={<EditSection />} />
            {/* category */}
            <Route path="contact" element={<ManageContact />} />
            <Route path="contact/add" element={<AddContact />} />
            <Route path="contact/edit/:id" element={<EditContact />} />

            <Route path="workingprocess" element={<EditWorkingProcess />} />
            <Route path="ceosay" element={<EditCeoSay />} />
            <Route path="social" element={<EditSocial />} />
            <Route path="copyright" element={<EditCopyRight />} />
            <Route path="footer" element={<EditFooter />} />
          </Route>
        </Routes>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
