import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAdmin from "../Hooks/useAdmin";

const RequireAdmin = ({ children }) => {
  const email = localStorage.getItem("email");
  // console.log(email);
  const { admin } = useAdmin(email);
  const location = useLocation();
  // console.log(adminLoading);

  if (!admin) {
    return <div className="loader"></div>;
  }
  if (!admin) {
    return <Navigate to="/login" sate={{ form: location }} replace />;
  }
  return children;
};

export default RequireAdmin;
