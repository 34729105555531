import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";
import Loading from "../../Loading/Loading";

const EditVideo = () => {
  const [dataLoadng, setDataLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideos] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    const getCategoryById = async () => {
      const url = `/api/media/${id}`;
      const { data } = await axiosBaseUrl.get(url);
      if (data.status) {
        setTitle(data.data.title);
        setDescription(data.data.description);
      }
    };
    getCategoryById();
  }, [id]);

  const addHandler = async (e) => {
    e.preventDefault();

    setDataLoading(true);

    let formdata = new FormData();

    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("video", video);

    try {
      const { data } = await axiosBaseUrl.put(`/api/media/${id}`, formdata);
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);

        toast(error.response.data.message);
        console.log(error);
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }
  return (
    <section className="section">
      <Helmet>
        <title>Update Video</title>
      </Helmet>
      <div className="section-header">
        <h1>Update Video</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Update Video and input not be empty</h4>
                  <Link to="/admin/video" className="btn btn-primary">
                    All Video
                  </Link>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Title</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="Title"
                        id="title"
                        className="form-control"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      description
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        row="5"
                        col="100%"
                        type="text"
                        name="description"
                        value={description}
                        id="description"
                        className="form-control"
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Video upload
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="file"
                        name="video"
                        id="video"
                        className="form-control"
                        accept=".mp4, .mkv"
                        onChange={(e) => {
                          setVideos(e.target.files[0]);
                        }}
                      />
                      {/* <img src={data.image_url} width="100px" alt="" /> */}
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={addHandler}
                    className="btn btn-primary"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditVideo;
