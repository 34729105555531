import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";
import Loading from "../../Loading/Loading";
import { Alert } from "react-bootstrap";

const EditContact = () => {
  const [dataLoadng, setDataLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_number, setContact_number] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errordata, setErrorData] = useState({
    errorMessage: "",
  });
  const { id } = useParams();

  useEffect(() => {
    const getCategoryById = async () => {
      const url = `/api/contact/${id}`;
      const { data } = await axiosBaseUrl.get(url);
      if (data.status) {
        const apiData = data.data.results;
        setName(apiData.name);
        setEmail(apiData.email);
        setContact_number(apiData.contact_number);
        setSubject(apiData.subject);
        setMessage(apiData.message);
      }
    };
    getCategoryById();
  }, [id]);

  const updateHandler = async (e) => {
    e.preventDefault();
    const inputData = {
      name,
      email,
      contact_number,
      subject,
      message,
    };
    try {
      const { data } = await axiosBaseUrl.put(`/api/contact/${id}`, inputData);
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
        setErrorData({ errorMessage: "" });
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        const erorMessage = error.response.data;
        console.log(erorMessage);
        if (erorMessage.status === false) {
          setErrorData({
            errorMessage: erorMessage?.message,
          });
        }
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }
  return (
    <section className="section">
      <Helmet>
        <title>Update Contact</title>
      </Helmet>
      <div className="section-header">
        <h1>Update Contact</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Update Contact and input not be empty</h4>
                  <Link to="/admin/contact" className="btn btn-primary">
                    All Contact
                  </Link>
                </div>

                <div className="card-body">
                  {errordata.errorMessage && (
                    <Alert color="danger">
                      <span className="text-danger">
                        {errordata.errorMessage}
                      </span>
                    </Alert>
                  )}

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter name"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Contact number
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter contact number"
                        name="contact_number"
                        onChange={(e) => setContact_number(e.target.value)}
                        value={contact_number}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">subject</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter subject"
                        name="contact_number"
                        onChange={(e) => setSubject(e.target.value)}
                        value={subject}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Message</label>
                    <div className="col-sm-9">
                      <textarea
                        id=""
                        className="form-control"
                        cols="30"
                        rows="10"
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={updateHandler}
                    className="btn btn-primary"
                  >
                    Update Contact
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditContact;
