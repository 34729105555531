import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";

import JoditEditor from "jodit-react";
import Loading from "../../Loading/Loading";

const config = {
  buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
};

const EditCeoSay = () => {
  const [filedImageError, setFiledImagError] = useState(null);
  const [dataLoadng, setDataLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [ceo_name, setCeo_name] = useState("");
  const [image, setImage] = useState("");
  const [image_url, setImage_url] = useState("");
  const editor = useRef(null);

  const getFooter = async () => {
    const url = `/api/ceosay/`;
    const { data } = await axiosBaseUrl.get(url);
    if (data.status) {
      if (data.data.results.length > 0) {
        setTitle(data.data.results[0].title);
        setDescription(data.data.results[0].description);
        setCeo_name(data.data.results[0].ceo_name);
        setImage_url(data.data.results[0].image);
      }
    }
  };

  useEffect(() => {
    getFooter();
  }, []);

  const onchaneCheckExtension = (fileData) => {
    var fileTypes = ["jpg", "jpeg", "png"];
    var extension = fileData.name.split(".").pop().toLowerCase();
    const isSuccess = fileTypes.indexOf(extension) > -1;
    if (!isSuccess) {
      setFiledImagError("Select image png,jpg,jpeg");
      return false;
    }
    setFiledImagError(null);
  };

  function handleFile(e) {
    onchaneCheckExtension(e.target.files[0]);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setImage(e.target.files[0]);
      setImage_url(reader.result);
    };
  }
  const UpdateHandler = async (e) => {
    e.preventDefault();
    if (filedImageError) {
      return false;
    }
    setDataLoading(true);
    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("ceo_name", ceo_name);

    try {
      const { data } = await axiosBaseUrl.post(`/api/ceosay/`, formData);
      if (data.status) {
        setDataLoading(false);

        toast.success(data.message);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        toast(error.response.data.message);
        console.log(error);
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }
  return (
    <section className="section">
      <Helmet>
        <title>Ceo Say</title>
      </Helmet>
      <div className="section-header">
        <h1>Update Ceo Say</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Update Ceo Say and input not be empty</h4>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Section Title
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Ceo Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="ceo_name"
                        onChange={(e) => setCeo_name(e.target.value)}
                        value={ceo_name}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Description
                    </label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={description}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) => setDescription(newContent)}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Image</label>
                    <div className="col-sm-9">
                      <input
                        data-height="100"
                        onChange={(e) => handleFile(e)}
                        type="file"
                        className="form-control"
                      />
                      <img src={image_url} width="100px" alt="" />
                      {filedImageError && <p>{filedImageError}</p>}
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={UpdateHandler}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditCeoSay;
