import React from "react";
import { Outlet } from "react-router-dom";
import HeaderNav from "../Header/HeaderNav";
import SidebarNav from "../Sidebar/SidebarNav";
import "./Layout.css";
const Layout = () => {
  return (
    <div className="main-wrapper main-wrapper-1">
      <HeaderNav />
      {/* main_sidebar */}
      <SidebarNav />
      <div className="main-content">
        <div className="section">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
