import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";
import Loading from "../../Loading/Loading";

const EditGallery = () => {
  const [filedImageError, setFiledImagError] = useState(null);
  const [dataLoadng, setDataLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    image_url: "",
  });
  const { id } = useParams();
  // const fileRef = useRef(null);

  useEffect(() => {
    const getCategoryById = async () => {
      const url = `/api/gallery/${id}`;
      const { data } = await axiosBaseUrl.get(url);
      if (data.status) {
        setData({
          name: data.data.name,
          image_url: data.data.image,
        });
      }
    };
    getCategoryById();
  }, [id]);
  const onchaneCheckExtension = (fileData) => {
    var fileTypes = ["jpg", "jpeg", "png"];
    var extension = fileData.name.split(".").pop().toLowerCase();
    const isSuccess = fileTypes.indexOf(extension) > -1;
    if (!isSuccess) {
      setFiledImagError("Select image png,jpg,jpeg");
      return false;
    }
    setFiledImagError(null);
  };

  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  function handleFile(e) {
    onchaneCheckExtension(e.target.files[0]);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      const newData = { ...data };
      newData["image"] = e.target.files[0];
      newData["image_url"] = reader.result;
      setData(newData);
      // console.log(reader.result); //base64encoded string
    };
  }

  const UpdateHandler = async (e) => {
    e.preventDefault();
    if (filedImageError) {
      return false;
    }
    setDataLoading(true);
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("name", data.name);

    try {
      const { data } = await axiosBaseUrl.put(`/api/gallery/${id}`, formData);
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
        setFiledImagError(null);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        toast(error.response.data.message);
        console.log(error);
        // localStorage.removeItem("token");
        // navigate("/login");
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }
  return (
    <section className="section">
      <Helmet>
        <title>Update gallery</title>
      </Helmet>
      <div className="section-header">
        <h1>Update gallery</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Add gallery and input not be empty</h4>
                  <Link to="/admin/gallery" className="btn btn-primary">
                    All gallery
                  </Link>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Gallery Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={(e) => handle(e)}
                        value={data.name}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Image</label>
                    <div className="col-sm-9">
                      <input
                        data-height="100"
                        onChange={(e) => handleFile(e)}
                        // onChange={(e) => {
                        //   setFiledImage(e.target.files[0]);
                        //   onchaneCheckExtension(e.target.files[0]);
                        // }}
                        type="file"
                        // {...register("image")}
                        className="form-control"
                      />
                      <img src={data.image_url} width="100px" alt="" />
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={UpdateHandler}
                    // disabled={filedImageError}
                    className="btn btn-primary"
                  >
                    Update Banner
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditGallery;
