import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import axiosBaseUrl from "../../../axiosBaseUrl";
import useGlobalContexts from "../../../Context/GlobalContext";

const ManageGallery = () => {
  const { setToggleClass } = useGlobalContexts();

  const [banners, setBanners] = useState([]);
  const [filterBanners, setFilterBanners] = useState([]);
  const [dataLoadng, setDataLoading] = useState(true);
  const [search, setSearch] = useState("");

  // get categories
  const getCategory = async () => {
    const url = `/api/gallery/getgallery`;
    try {
      const { data } = await axiosBaseUrl.get(url);
      if (data.status) {
        setDataLoading(false);
        setBanners(data.data.results);
        setFilterBanners(data.data.results);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setToggleClass(false);
    getCategory();
  }, [setToggleClass]);
  useEffect(() => {
    const filteredItems = banners.filter(
      (item) => item.name && item.name.toLowerCase().match(search.toLowerCase())
    );
    setFilterBanners(filteredItems);
  }, [search, banners]);
  // if (!dataLoadng) {
  //   return <h1>Loading...</h1>;
  // }
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `/api/gallery/${id}`;
          const { data } = await axiosBaseUrl.delete(url);
          if (data.status) {
            Swal.fire(`Deleted!`, data.message, `success`);
            // toast.success(data.message);
          } else {
            Swal.fire(`Deleted!`, data.message, `success`);
          }
          getCategory();
        } catch (error) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            console.log(error.response.data.msg);
          }
        }
      }
    });
  };
  const columns = [
    {
      name: "Gallery Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "image",
      selector: (row) => <img src={row.image} alt={row.name} width="100px" />,
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`edit/${row.id}`} className="btn btn-info">
            <span>
              <i className="fa-solid fa-pen-to-square"></i>
            </span>
          </Link>

          <button
            className="btn btn-danger m-1"
            onClick={() => handleDelete(row.id)}
          >
            <i className="fa-solid fa-trash"></i>
          </button>
        </>
      ),
    },
  ];
  return (
    <section className="section">
      <Helmet>
        <title>Manage gallery</title>
      </Helmet>
      <div className="section-header">
        <h1>All gallery</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <Link to="/admin/gallery/add" className="btn btn-primary">
                  Add gallery
                </Link>
              </div>
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={filterBanners}
                  highlightOnHover
                  pagination
                  progressPending={dataLoadng}
                  fixedHeader
                  dense
                  pointerOnHover
                  subHeader
                  subHeaderComponent={
                    <input
                      type="text"
                      placeholder="Enter search term"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManageGallery;
