import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";
import Loading from "../../Loading/Loading";
import useGlobalContexts from "../../../Context/GlobalContext";
import { useEffect } from "react";

const AddGalleryPost = () => {
  const { setToggleClass } = useGlobalContexts();

  const [filedImageError, setFiledImagError] = useState(null);
  const [dataLoadng, setDataLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    setToggleClass(false);
    getCategory();
  }, [setToggleClass]);

  // get categories
  const getCategory = async () => {
    const url = `/api/post/getpost`;
    try {
      const { data } = await axiosBaseUrl.get(url);
      if (data.status) {
        setCategories(data.data.results);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        console.log(error);
      }
    }
  };

  const onchaneCheckExtension = (fileData) => {
    var fileTypes = ["jpg", "jpeg", "png"];
    var extension = fileData.name.split(".").pop().toLowerCase();
    const isSuccess = fileTypes.indexOf(extension) > -1;
    if (!isSuccess) {
      setFiledImagError("Select image png,jpg,jpeg");
      return false;
    }
    setFiledImagError(null);
  };

  const [data, setData] = useState({
    name: "",
    image: "",
    post_id: "",
    image_url: "",
  });

  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  function handleFile(e) {
    onchaneCheckExtension(e.target.files[0]);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      const newData = { ...data };
      newData["image"] = e.target.files[0];
      newData["image_url"] = reader.result;
      setData(newData);
      // console.log(reader.result); //base64encoded string
    };
  }

  const addHandler = async (e) => {
    e.preventDefault();
    if (filedImageError) {
      return false;
    }
    setDataLoading(true);
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("name", data.name);
    formData.append("post_id", data.post_id);

    try {
      const { data } = await axiosBaseUrl.post(`/api/gallerypost/`, formData);
      if (data.status) {
        setDataLoading(false);

        toast.success(data.message);
        setFiledImagError(null);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        toast(error.response.data.message);
        console.log(error);
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }
  return (
    <section className="section">
      <Helmet>
        <title>Add Gallery post</title>
      </Helmet>
      <div className="section-header">
        <h1>Add Gallery post</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Add Gallery post and input not be empty</h4>
                  <Link to="/admin/gallerypost" className="btn btn-primary">
                    All Gallery
                  </Link>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={(e) => handle(e)}
                        value={data.name}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Select Post
                    </label>

                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="post_id"
                        onChange={(e) => handle(e)}
                        required
                      >
                        <option>Select Post</option>
                        {categories &&
                          categories.map((item, i) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Image</label>
                    <div className="col-sm-9">
                      <input
                        data-height="100"
                        onChange={(e) => handleFile(e)}
                        type="file"
                        className="form-control"
                      />
                      <img src={data.image_url} width="100px" alt="" />
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={addHandler}
                    className="btn btn-primary"
                  >
                    Add Gallery Post
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddGalleryPost;
