import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";

import JoditEditor from "jodit-react";
import Loading from "../../Loading/Loading";
import useGlobalContexts from "../../../Context/GlobalContext";
import { useEffect } from "react";

const config = {
  buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
};

const ServicesSection = () => {
  const { setToggleClass } = useGlobalContexts();

  const [heading, setHeading] = useState("");
  const [sub_heading, setSub_heading] = useState("");
  const [meta_title, setMeta_title] = useState("");
  const [meta_description, setMeta_Description] = useState("");
  const [description, setDescription] = useState("");
  const [section_type, setSection_type] = useState("");

  const editor = useRef(null);
  const [dataLoadng, setDataLoading] = useState(false);
  useEffect(() => {
    setToggleClass(false);
  }, [setToggleClass]);
  const UpdateHandler = async (e) => {
    e.preventDefault();
    setDataLoading(true);
    const Data = {
      heading,
      sub_heading,
      meta_title,meta_description,
      description,
      section_type,
    };
    try {
      const { data } = await axiosBaseUrl.post(`/api/section/`, Data);
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        console.log(error);
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }

  return (
    <section className="section">
      <Helmet>
        <title>Add  Section</title>
      </Helmet>
      <div className="section-header">
        <h1>Add  Section</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Add section and input not be empty</h4>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                       Heading
                    </label>
                    <div className="col-sm-9">
                    <input type="text" className="form-control" value={heading} placeholder="Heading"  onChange={(e) => setHeading(e.target.value)}  />
                   
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Sub Heading
                    </label>
                    <div className="col-sm-9">
                    <textarea rows = "5" value={sub_heading} cols = "50" name = "description" onChange={(e) => setSub_heading(e.target.value)}  className="form-control" placeholder="Sub Heading">
            
         </textarea>
                     
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                     Meta title
                    </label>
                    <div className="col-sm-9">
                    <input type="text" className="form-control" value={meta_title} placeholder="Meta title"  onChange={(e) => setMeta_title(e.target.value)}  />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                     Meta Description
                    </label>
                    <div className="col-sm-9">
                    <textarea rows = "5" value={description} cols = "50" name = "description" onChange={(e) => setMeta_Description(e.target.value)}  className="form-control" placeholder="Meta description ">
         </textarea>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                     Description
                    </label>
                    <div className="col-sm-9">
                    <textarea rows = "5" value={description} cols = "50" name = "description" onChange={(e) => setDescription(e.target.value)}  className="form-control" placeholder=" Description ">
         </textarea>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Section type
                    </label>

                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="sction_type"
                        required
                        onChange={(e) => setSection_type(e.target.value)}
                      >
                        <option value="">Select Section type</option>
                        <option value="news">News</option>
                        <option value="service">Service</option>
                        <option value="about">About</option>
                        <option value="count">Count Down</option>
                        <option value="concern">Concern</option>
                        <option value="gallery">Gallery</option>
                        <option value="video">Video</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={UpdateHandler}
                    className="btn btn-primary btn-lg"
                  >
                    Add section
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
