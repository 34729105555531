import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import axiosBaseUrl from "../../../axiosBaseUrl";

import JoditEditor from "jodit-react";
import Loading from "../../Loading/Loading";

const config = {
  buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
};

const EditCopyRight = () => {
  // const [getFooterData, setGetFooter] = useState({});
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const editor = useRef(null);
  const [dataLoadng, setDataLoading] = useState(false);

  const getFooter = async () => {
    setDataLoading(true);

    const url = `/api/copyright/`;
    const { data } = await axiosBaseUrl.get(url);
    if (data.status) {
      setDataLoading(false);

      if (data.data.results.length > 0) {
        setTitle(data.data.results[0].title);
        setDescription(data.data.results[0].description);
      }
    }
  };

  useEffect(() => {
    getFooter();
  }, []);

  const UpdateHandler = async (e) => {
    e.preventDefault();
    setDataLoading(true);
    const footerData = {
      title: title,
      description: description,
    };
    try {
      const { data } = await axiosBaseUrl.post(`/api/copyright/`, footerData);
      if (data.status) {
        setDataLoading(false);
        toast.success(data.message);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        setDataLoading(false);
        toast(error.response.data.message);
        console.log(error);
        // localStorage.removeItem("token");
        // navigate("/login");
      }
    }
  };
  if (dataLoadng) {
    return <Loading />;
  }

  return (
    <section className="section">
      <Helmet>
        <title>Update Copy Right</title>
      </Helmet>
      <div className="section-header">
        <h1>Update Copy Right</h1>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 ">
            <div className="card">
              <form className="" noValidate>
                <div className="card-header justify-content-between">
                  <h4>Update Copy Right and input not be empty</h4>
                </div>

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Title</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Copy right Widgets
                    </label>
                    <div className="col-sm-9">
                      <JoditEditor
                        ref={editor}
                        value={description}
                        config={config}
                        tabIndex={1}
                        onChange={(newContent) => setDescription(newContent)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="button"
                    onClick={UpdateHandler}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditCopyRight;
