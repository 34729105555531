import React from "react";
import { Spinner } from "react-bootstrap";
import "./Loading.css";
const Loading = () => {
  return (
    <>
      <div className="conainer">
        <div className="row">
          <div className="loading_ara">
            <h1>loading......</h1>
            <Spinner animation="grow" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
