import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axiosBaseUrl from "../../../axiosBaseUrl";
import "./Video.css";
import useGlobalContexts from "../../../Context/GlobalContext";
const ManageVideo = () => {
  const { setToggleClass } = useGlobalContexts();

  const [medias, setVideos] = useState([]);

  // get categories
  const getCategory = async () => {
    const url = `/api/allmedia/videos`;
    try {
      const { data } = await axiosBaseUrl.get(url);
      if (data) {
        setVideos(data.data.results);
      }
    } catch (error) {
      if (
        error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setToggleClass(false);
    getCategory();
  }, [setToggleClass]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `/api/media/${id}`;
          const { data } = await axiosBaseUrl.delete(url);
          if (data.status) {
            Swal.fire(`Deleted!`, data.message, `success`);
            // toast.success(data.message);
          } else {
            Swal.fire(`Deleted!`, data.message, `success`);
          }
          getCategory();
        } catch (error) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            toast.error(error.response.data.message);
          }
        }
      }
    });
  };
  return (
    <section className="section">
      <Helmet>
        <title>Manage video</title>
      </Helmet>
      <div className="section-header">
        <h1>All Video</h1>
        <div className="card-header">
          <Link to="/admin/video/add" className="btn btn-primary">
            Add Video
          </Link>
        </div>
      </div>
      <div className="section-body">
        <div className="row">
          {medias &&
            medias.map((media) => {
              return (
                <div className="col-lg-4 mb-5">
                  <div className="video_item">
                    <div>
                      <Link to={`edit/${media.id}`} className="btn btn-info">
                        <span>
                          <i className="fa-solid fa-pen-to-square"></i>
                        </span>
                      </Link>
                      <button
                        className="btn btn-danger m-1"
                        onClick={() => handleDelete(media.id)}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </div>
                    <div className="video_play">
                      <video preload="auto" width="100%" height="240" controls>
                        <source src={media.video} />
                        ;Your browser does not support the video tag.
                      </video>
                    </div>
                    <h3>{media.title}</h3>
                    <div className="description_video">{media.description}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default ManageVideo;
