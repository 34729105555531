import React, { useEffect } from "react";
import useGlobalContexts from "../../Context/GlobalContext";
import logo from "../assets/logo.png";
import SidebarItem from "./SidebarItem";
import { NavLink } from "react-router-dom";

const SidebarNav = () => {
  const { toggleMobile, setToggleMobile } = useGlobalContexts();
  const handleToggle = () => {
    setToggleMobile(!toggleMobile);
  };

  const routes = [
    {
      to: "/admin",
      icon: "fas fa-home",
      display_name: "Dashboard",
    },
    {
      to: "category",
      icon: "fa-solid fa-map-location",
      display_name: "Category",
      subMenus: [
        {
          to: "category",
          icon: "fa-solid fa-map-location",
          display_name: "Manage category",
        },
        {
          to: "category/add",
          icon: "fa-solid fa-map-location",
          display_name: "Add category",
        },
      ],
    },
    {
      to: "banner",
      icon: "fa-brands fa-bandcamp",
      display_name: "Banner",
      subMenus: [
        {
          to: "banner",
          icon: "fa-solid fa-map-location",
          display_name: "Manage banner",
        },
        {
          to: "banner/add",
          icon: "fa-solid fa-map-location",
          display_name: "Add banner",
        },
      ],
    },
    {
      to: "post",
      icon: "fa-brands fa-usps",
      display_name: "Post",
      subMenus: [
        {
          to: "post",
          icon: "fa-solid fa-map-location",
          display_name: "Manage post",
        },
        {
          to: "post/add",
          icon: "fa-solid fa-paste",
          display_name: "Add post",
        },
      ],
    },
    {
      to: "countdown",
      icon: "fa-solid fa-mountain-sun",
      display_name: "Countdown",
      subMenus: [
        {
          to: "countdown",
          icon: "fa-solid fa-map-location",
          display_name: "Manage countdown",
        },
        {
          to: "countdown/add",
          icon: "fa-solid fa-paste",
          display_name: "Add countdown",
        },
      ],
    },
    {
      to: "gallery",
      icon: "fa-solid fa-dice-d6",
      display_name: "Gallery",
      subMenus: [
        {
          to: "gallery",
          icon: "fa-solid fa-map-location",
          display_name: "Manage gallery",
        },
        {
          to: "gallery/add",
          icon: "fa-solid fa-paste",
          display_name: "Add gallery",
        },
      ],
    },
    {
      to: "gallerypost",
      icon: "fa-solid fa-dice-d6",
      display_name: "Gallery Post",
      subMenus: [
        {
          to: "gallerypost",
          icon: "fa-solid fa-map-location",
          display_name: "Manage gallery Post",
        },
        {
          to: "gallerypost/add",
          icon: "fa-solid fa-paste",
          display_name: "Add gallery post",
        },
      ],
    },
    {
      to: "video",
      icon: "fa-solid fa-video-slash",
      display_name: "Video",
      subMenus: [
        {
          to: "video",
          icon: "fa-solid fa-map-location",
          display_name: "Manage video",
        },
        {
          to: "video/add",
          icon: "fa-solid fa-paste",
          display_name: "Add video",
        },
      ],
    },
    {
      to: "section",
      icon: "fa-solid fa-section",
      display_name: "Section",
      subMenus: [
        {
          to: "section",
          icon: "fa-solid fa-map-location",
          display_name: "Manage Services",
        },
        {
          to: "section/add",
          icon: "fa-solid fa-map-location",
          display_name: "Add Services",
        },
      ],
    },
    {
      to: "contact",
      icon: "fa-solid fa-location",
      display_name: "Contact",
      subMenus: [
        {
          to: "contact",
          icon: "fa-solid fa-map-location",
          display_name: "Manage Contact",
        },
        {
          to: "contact/add",
          icon: "fa-solid fa-map-location",
          display_name: "Add Contact",
        },
      ],
    },

    {
      to: "workingprocess",
      icon: "fa-solid fa-person-digging",
      display_name: "Workingprocess",
    },
    {
      to: "ceosay",
      icon: "fa-solid fa-people-roof",
      display_name: "Ceosay",
    },
    {
      to: "about",
      icon: "fa-regular fa-address-card",
      display_name: "About",
    },
    {
      to: "logo",
      icon: "fa-brands fa-atlassian",
      display_name: "Logo",
    },
    {
      to: "social",
      icon: "fa-solid fa-icons",
      display_name: "Social",
    },
    {
      to: "copyright",
      icon: "fa-regular fa-copyright",
      display_name: "Copyright",
    },
    {
      to: "footer",
      icon: "fa-solid fa-cubes-stacked",
      display_name: "Footer",
    },
  ];
  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".custom_dropdown_menu").forEach((el) => {
      el.classList.remove("show");
    });
  };
  const dremoveActiveClassFromSubMenu = () => {
    document.querySelectorAll(".dropdown-item").forEach((el) => {
      el.classList.remove("active");
    });
  };

  useEffect(() => {
    let menuItems = document.querySelectorAll(".dropdown-toggle");
    // console.log(menuItems);
    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        // console.log(el);
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems.forEach((el) => el.classList.remove("show"));
        el.classList.toggle("show");
        // console.log(next);
        if (next !== null) {
          next.classList.toggle("show");
        }
      });
    });
  }, []);
  useEffect(() => {
    let dmenuItems = document.querySelectorAll(".dropdown-item");
    dmenuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        dremoveActiveClassFromSubMenu();
        dmenuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  return (
    <div className="main-sidebar sidebar-style-2">
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand sidebar_custom_brand">
          <NavLink to="/">
            <img alt="logo" src={logo} className="header-logo" />
            <span className="logo-name">AIT</span>
          </NavLink>
          <div onClick={handleToggle}>
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        </div>
        <ul className="sidebar-menu">
          <li className="menu-header">Main</li>

          {/* <li className="dropdown active">
            <Link to="dashboard" className="nav-link">
              <i className="fas fa-home"></i>
              <span>Dashboard</span>
            </Link>
          </li> */}
          {/* category start */}
          {routes.map((menu, index) => (
            <SidebarItem menu={menu} key={index} />
          ))}

          {/* category end */}
        </ul>
      </aside>
    </div>
  );
};

export default SidebarNav;
